<template>
  <splide class="process-list"
  :options="{gap: '2rem',pagination: false, autoWidth: true}"
  v-if="this.processes"
  :slides="processes"
  >

  <splide-slide
      v-for="process in processes"
      :key="process._id"
      >
      <div class="process-box box-shadow radius"
        :class="{selected: process._id === selectedId}"
        @click="() => selectProcess(process._id)"
      >
        <div class="line"></div>
        <div class="infos">
          <div class="title-processname">{{process.name}}</div>
          <p class="infosupp"><i v-html="process.description"></i></p>
        </div>

        <div class="global-param">
          <div class="param ghost"></div>
          <div class="param">
            <img src="@/assets/pictos/picto_param_purple.svg">
            <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
          </div>
          <div class="cont-param">
            <div class="elem-param" @click="() =>duplicate(process._id)">duplicate</div>
            <div class="elem-param" @click="() =>editProcess(process)">edit</div>
            <div class="elem-param delete" @click="() =>deleteProcess(process._id)">delete</div>
          </div>
        </div>
      </div>

  </splide-slide>
</splide>

</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import NewProcess from './processPartials/newProcess.vue';

export default {
  name: 'processList',
  components: {
    Splide,
    SplideSlide,
  },
  computed: {
    processes() {
      return this.$store.state.company.companyInfo.processes || [];
    },
    selectedId() {
      return this.$store.state.company.currentProcessId;
    },
  },
  methods: {
    selectProcess(id) {
      this.$store.dispatch('company/setCurrentProcessId', id);
    },
    newProcess() {
      this.$modal.show(NewProcess, {
        callBack: (newProcess) => {
          this.$store.dispatch('company/setCurrentProcessId', newProcess._id);
          this.$modal.hide('new-process');
        },
      },
      {
        name: 'new-process',
        height: 'auto',
        width: '650px',
        classes: ['overflow'],
      });
    },
    editProcess(process) {
      this.$modal.show(NewProcess, {
        callBack: (newProcess) => {
          this.$store.dispatch('company/setCurrentProcessId', newProcess._id);
          this.$modal.hide('new-process');
        },
        process,
      },
      {
        name: 'new-process',
        height: 'auto',
        width: '650px',
        classes: ['overflow'],
      });
    },

    duplicate(id) {
      this.$store.dispatch('company/duplicateProcess', id);
    },
    deleteProcess(id) {
      this.$store.dispatch('company/deleteProcess', id);
    },
  },
};
</script>

<style lang="scss" >
  @import "@/assets/scss/_splide.scss";

  @import "@/assets/scss/_variables.scss";
.process-list{
  height: 100%;
  .title-processname{
    font-size: $fnt-18;
  }
  .process-box {
    position: relative;
    width: 14vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    cursor: pointer;
    margin: 0;
    padding: 0.5vh;
    padding-top: 1vh;
    margin-top: 2vh;
    border: 3px solid #151515;
    box-sizing: content-box;
    background-color: #ffffff;
    transition: all 0.2s;

    .line {
      width: 110%;
      height: 0.5vh;
      background-color: #ffffff;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      &.radius {
        // border-top-left-radius: 1vh;
        // border-top-right-radius: 1vh;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;
      }
    }
    &.radius {
      overflow: hidden;
    }
    .infos {
      text-align: left;
      width: 100%;
      font-size: 1.6vh;
      .name {
        font-size: 1.4vh;
        font-style: italic;
      }
      .title-processname {
        font-family: "MontserratBold";
        font-weight: 600;
        font-size: 2vh;
        color: #8c28e1;
        width: calc(100% - 30px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          font-family: "MontserratRegular";
          font-style: italic;
        }
      }
      .infosupp {
        display: flex;
        flex-direction: row;
        margin-top: 1vh;
        margin-bottom: 1vh;
        flex-wrap: wrap;
        justify-content: space-between;
        .elem-infosupp {
          position: relative;
          margin-right: 2vh;
          display: flex;
          flex-direction: row;
          width: 40%;
          margin: 0.7vh;
          .picto {
            position: relative;
            width: 1.8vh;
            font-size: 0;
            margin-right: 0.5vh;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 150%;
            }
            .small {
              width: 100% !important ;
            }
          }
          .txt {
            font-size: 10px;
          }
        }
      }
    }
    &:hover {
      .line {
        background-color: $green_color;
      }
    }
    &.selected {
      border: 3px solid #48ffaf;
      width: calc(14vw - 4px);
    }
  }
  // .process-box{
  //   cursor: pointer;
  //   padding: 11px 6px 5px 6px;
  //   background-color: #ffffff;
  //   // border: 4px solid #ffffff;
  //   margin-bottom: 10px;
  //   flex-direction: column;
  //   position: relative;
  //   width: 12vw;
  //   height: 8vh;
  //   margin-top: 1vh;
  //   .line{
  //     width: 100%;
  //     height: 5px;
  //     background-color: #ffffff;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //   }
  //   p{
  //     margin-top: 1vh;
  //     margin-bottom: 1vh;
  //     max-height: 40px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     font-size: $fnt-14;
  //   }
  //   h4{
  //     display: block;
  //     width: 100%;
  //   }
  //   &:hover{
  //     .line{
  //       background-color: $green-color;
  //     }
  //   }
  //   &.selected{
  //     border: none;
  //     .line{
  //       background-color: $logo_color;
  //     }
  //   }
  // }
  .new-process{
    margin-bottom: 2vh;
    .btn-green{
      margin: 0;
      width: 100%;
    }
  }
  .splide__arrow{
    top: -0.75vh;
    background-color: #48ffaf;
    opacity: 1;
    &.splide__arrow--next{
      right:0;
    }
    &.splide__arrow--prev{
      right:2vw;
      left: auto;
    }
  }
}
</style>
