<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Process Management
          <div class="other">
            <div class="cont-talent-contact">
              <div
                class="btn-green radius"
                @click="newProcess"
              >
                Add New Process
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="elem-flex contentbox radius box-shadow offer-cont">
      <div class="process-list-cont">
        <div class="title-processes">
           Select Process
        </div>
        <process-list />
      </div>

    </div>
    <div class="cont-selected-process radius">
      <processDefinition></processDefinition>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
// import PerfectScrollbar from 'perfect-scrollbar';
import processDefinition from '@/components/EmployeeComponents/JobManagement/processDefinition.vue';
import processList from '@/components/EmployeeComponents/JobManagement/processList.vue';
import NewProcess from '@/components/EmployeeComponents/JobManagement/processPartials/newProcess.vue';

export default {
  name: 'ProcessManagement',
  components: {
    processList,
    processDefinition,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('company', {
      companyInfo: (state) => state.companyInfo,
      loading: (state) => state.loadingInfo,
    }),
  },
  mounted() {
    this.$store.dispatch('company/getInfo');

    // const ps = new PerfectScrollbar('#list-process', {
    //   wheelPropagation: false,
    //   suppressScrollX: true,
    // });
    // ps.update();
  },
  methods: {
    newOfferForm() {
      this.$store.dispatch('company/setNewOfferApplication');
      // this.$router.push('/myCompany/job-management?tab=3');
    },
    newProcess() {
      this.$modal.show(NewProcess, {
        callBack: (newProcess) => {
          this.$store.dispatch('company/setCurrentProcessId', newProcess._id);
          this.$modal.hide('new-process');
        },
      },
      {
        name: 'new-process',
        height: 'auto',
        width: '650px',
        classes: ['overflow'],
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
#list-offer{
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
}
   .title-processes{
    color: #48ffaf;
    font-family: 'MontserratBold';
    font-weight: 600;
    font-size: 2vh;
  }
  .process-list-cont{
    height: 16vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 1vh;
    width: calc(100% - 2vh);
  }
  .subtitle-page{
    margin-bottom: 1vh;
  }

  .offer-cont{
    background-color: $text-color;
    margin-bottom: 3vh;
  }

  .title-page{
    &.shortened{
      font-size: 2vh;
      margin-bottom: .5vh;
    }
  }
  .cont-talent-contact{
    display: flex;
    justify-content: flex-end;
  }
  .cont-selected-process{
    padding: 1vh;
    background-color: #f0f0f0;
  }
</style>
