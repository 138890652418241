<template>
  <div class="form-step">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="subtitle-page">
      New process
    </div>
    <div class="cont-input name-input">
      <!-- <label> Name</label> -->
      <input class="v-select" :placeholder="'Name'" v-model="name" />
    </div>
    <div class="cont-input description-input">
      <!-- <label> Description </label> -->
      <!-- <input class="v-select" :placeholder="'Description'" v-model="description" /> -->
      <wysiwyg class="radius box-shadow" v-model="description" />
    </div>
    <div class="btn-green radius" @click="submit"> Submit</div>
  </div>
</template>

<script>
export default {
  name: 'NewProcess',
  props: {
    callBack: {
      type: Function,
      default: () => {},
    },
    process: {
      type: Object,
    },
  },
  data() {
    return {
      name: '',
      description: '',
    };
  },
  computed: {
    new() {
      return !this.process;
    },
  },
  methods: {
    submit() {
      if (this.new) {
        this.$store.dispatch('company/addProcess', { name: this.name, description: this.description }).then((process) => {
          this.callBack(process);
        });
      } else {
        this.$store.dispatch('company/editProcess', { name: this.name, description: this.description, _id: this.process._id }).then((process) => {
          this.callBack(process);
        });
      }
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'new-process');
    },
  },
  mounted() {
    if (!this.new) {
      this.name = this.process.name || '';
      this.description = this.process.description || '';
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.form-step{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% - 2vh);
  padding: 1vh;
  .title-section{
    width: 90%;
    text-align: left;
    .cont-delete{
      span{
        font-family: 'MontserratRegular';
        font-size: $mini_fs;
        cursor: pointer;
        &:hover{
          color: $color_crimson;
          text-decoration: underline;
        }
      }
    }
  }
  .cont-input{
    width: 100%;
    margin-bottom: 1vh;
    .select-switch{
      margin-top: 0.5vh;
    }
    .v-select{
      width: 100%;
    }
    .select-switch{
      justify-content: space-between;
    }
  }
  .btn-green{
    margin-top: 1vh;
  }
}
</style>
