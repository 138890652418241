<template>
<div class="cont-process-definition elem-flex mh71 contentbox">
  <div class="global-procdef" v-if="!process">
    <h3>Please select Process</h3>
    <div class="steps-container"></div>
  </div>
  <div class="global-procdef" v-if="stateProcess">
    <div class="subtitle-page wother">
      Steps
      <div class="other">
        <div class="btn-green radius"  @click="this.addStep"> Add Step</div>
      </div>
    </div>
    <div id="list-steps">
      <draggable
        v-model="steps"
        draggable=".step"
        @change="updateOrder"
        class="steps-container"
      >
      <div class="step radius box-shadow" v-for="(step, index) in steps" :key="step._id">
        <div class="left-step">
          <div class="number-step">
            {{index + 1}}
          </div>
        </div>
        <div class="right-step">
          <div class="info-step">
            <div class="subtitle-page">
              {{step.name}}
            </div>
            <div class="title-type">
              {{step.action}}
            </div>
            <div class="desc-step" v-html="step.description">
            </div>
          </div>

          <div class="global-param">
            <div class="param ghost"></div>
            <div class="param">
              <img src="@/assets/pictos/picto_param_purple.svg">
              <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
            </div>
            <div class="cont-param">
              <div class="elem-param" @click="() => editStep(step._id)">Edit step</div>
            </div>
          </div>
        </div>
      </div>
      </draggable>

    </div>
  </div>
</div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import draggable from 'vuedraggable';
import StepForm from './processPartials/stepForm.vue';

export default {
  name: 'processDefinition',
  components: {
    draggable,
  },
  computed: {

    stateProcess() {
      return this.$store.getters['company/currentProcess'];
    },

  },
  data() {
    return {
      process: {},
      steps: [],
    };
  },
  mounted() {
    this.process = { ...this.$store.getters['company/currentProcess'] };
    this.steps = this.process.steps;
  },
  watch: {
    stateProcess() {
      this.process = { ...this.$store.getters['company/currentProcess'] };
      this.steps = this.process.steps;
      const psSteps = new PerfectScrollbar('#list-steps', {
        wheelPropagation: false,
        wheelSpeed: 1,
      });
      psSteps.update();
    },
  },
  methods: {
    addStep() {
      this.$modal.show(StepForm, {
        callbackSubmit: (stepValues) => {
          this.$store.dispatch('company/addStep', stepValues).then(() => {
            this.$modal.hide('edit-step');
          });
        },
      },
      {
        name: 'edit-step',
        height: 'auto',
        width: '1000px',
        scrollable: true,
        maxHeight: 500,
        minHeight: 100,
        classes: ['overflow'],
      });
    },
    editStep(id) {
      this.$modal.show(StepForm, {
        step: this.$store.getters['company/currentProcess'].steps.find((step) => step._id === id),
        template: true,
        callbackSubmit: (stepValues) => {
          this.$store.dispatch('company/editStep', stepValues).then(() => {
            this.$modal.hide('edit-step');
          });
        },
        deleteStep: () => {
          if (id) {
            this.$store.dispatch('company/deleteStep', id).then(() => {
              this.$modal.hide('edit-step');
            });
          }
        },
      },
      {
        name: 'edit-step',
        height: 'auto',
        width: '1000px',
        scrollable: true,
        maxHeight: 500,
        minHeight: 100,
        classes: ['overflow'],
      });
    },

    updateOrder() {
      this.$store.dispatch('company/editProcess', { _id: this.process._id, steps: this.steps });
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .global-procdef{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 71vh;
  }
  .cont-process-definition{
    width: 100%;
    background-color: transparent;
  }
  #list-steps{
    position: relative;
    overflow: hidden;
    height: 46vh;
  }
  .steps-container{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .step{
      width: calc(calc(100% - 4vw) / 3);
      background-color: $logo_color_dark;
      margin-right: 2vw;
      margin-bottom: 2vh;
      height: 15vh;
      &:nth-child(3n){
        margin-right: 0;
      }
      .left-step{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 3vw;
        padding: 0;
        .number-step{
          height: 15vh;
          line-height: 15vh;

        }
      }
      .right-step{
        background-color: #ffffff;
        .info-step{
          .title-step{
            font-size: $label_fs;
          }
          .title-type{
            font-size: $label_min_fs;
          }
          .desc-step{
            font-size: $mini_fs;
            max-height: 48px;
          }
        }
      }
    }
  }
  .button-add{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
</style>
